import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
// 登录验证
export default new Vuex.Store({
    state: {
        user: false,
        loginFlag: false,
        editData: {}
    },
    mutations: {
        // 登录
        login(state, user) {
            state.user = user;
            state.loginFlag = true;

            if (user.token) {
                localStorage.setItem("token", user.token);
            }

        },
        // 登录
        logout(state) {
            state.user = false;
            state.loginFlag = false;
        },
        editItem(state, data) {
            state.editData = data;
        },

    },
    getters: {
        user: state => state.user,
        loginFlag: state => state.loginFlag,
        editData: state => state.editData
    }
})