<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
.el-notification {
  transform: scale(0.5) translate(40%, -20%);
}

@font-face {
  font-family: DroidSansChinese;
  src: url("./static/font/DroidSansChinese.ttf");
}

@font-face {
  font-family: ArchivoRegular;
  src: url("./static/font/Archivo-Regular.ttf");
}

@font-face {
  font-family: ArchivoBold;
  src: url("./static/font/Archivo-Bold.ttf");
}

@font-face {
  font-family: PlayfairDisplayBold;
  src: url("./static/font/PlayfairDisplay-Bold.ttf");
}

@font-face {
  font-family: PlayfairDisplayRegular;
  src: url("./static/font/PlayfairDisplay-Regular.ttf");
}

@font-face {
  font-family: PlayfairDisplayItalic;
  src: url("./static/font/PlayfairDisplay-Italic.ttf");
}

* {
  box-sizing: border-box;
  margin: 0;
  font-family: ArchivoRegular, DroidSansChinese;
}
a,
img,
button,
input,
div,
textarea {
  -webkit-tap-highlight-color: transparent;
}

.el-dialog__body {
  padding: 0 !important;
}

.el-dialog__header {
  padding: 0 !important;
}

.closeTitle {
  padding: 10px;
  font-size: 8px;
  text-align: center;
  padding-top: 20px;
}
.closeBtnList {
  padding: 10px;
  display: flex;
  font-size: 6px;
  align-items: center;

  // flex-direction: row-reverse;
  justify-content: center;

  .cancel {
    cursor: pointer;
    border: 0.5px solid #000;
    padding: 3px 10px;
    color: #000;
    border-radius: 2px;
    transition: 0.5s;
    margin-right: 10px;
  }

  .cancel:hover {
    background-color: #000;
    color: #fff;
  }

  .sure {
    cursor: pointer;
    background-color: #000;
    color: #fff;
    border: 0.5px solid #000;
    border-radius: 2px;
    transition: 0.5s;
    padding: 3px 10px;
  }

  //   .sure:hover {
  //     background-color: #fff;
  //     color: #000;
  //   }
}

::-webkit-scrollbar {
  width: 3px; /* 设置滚动条的宽度 */
}

::-webkit-scrollbar-track {
  /* background: #; 设置滚动条轨道的背景色 */
  opacity: 0.5;
}

::-webkit-scrollbar-thumb {
  background: #000000b6; /* 设置滚动条滑块的背景色 */
}
</style>
