<template>
  <div class="contentBody" v-on:scroll="scrollFun">
    <div class="header" :class="{ sticky: this.isFixed }">
      <div class="headerContent">
        <div
          class="headerLeft"
          v-appear-animation="{ appearClass: 'animation-in-left' }"
          @click="goHome()"
        >
          Bunnytown<span>起源创新</span>
        </div>
        <div
          class="headerRight"
          v-appear-animation="{ appearClass: 'animation-in-right' }"
        >
          <div class="noLogin">
            <div class="block" @click="goHome()">首页</div>
            <div class="block" @click="goCreateActivity()">信息发布</div>
            <div class="block" @click="maskShow = true" v-if="!loginFlag">
              注册/登录
            </div>
            <div class="block" v-else @click="dialogVisible = true">
              <img :src="user.avatar_url" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="imgTop">
      <img src="../../static/img/demoTop.jpg" alt="" />
    </div>

    <div class="body">
      <div class="actTitle">
        <div
          class="actTop"
          v-appear-animation="{ appearClass: 'animation-in-left' }"
        >
          Bunnytown 活动
        </div>

        <!-- <div
          class="actBottom"
          v-appear-animation="{ appearClass: 'animation-in-right' }"
        >
          这里放一段活动的介绍这里放一段活动的介绍这里放一段活动的介绍这里放一段活动的介绍这
          里放一段活动的介绍
        </div> -->
      </div>
      <div class="actList">
        <div
          class="actItem"
          v-for="(item, index) in actList"
          :key="index"
          v-appear-animation="{ appearClass: 'animation-in-bottom' }"
        >
          <div class="actImg" @click="goDetail(index)">
            <img :src="item.pic" alt="" />
          </div>
          <div class="actLine">
            {{ item.title }}
          </div>
          <div class="actSub">
            <div @click="goDetail(index)">查看全部</div>

            <div class="editBtn" v-show="btnShow(item)" @click="goEdit(item)">
              编辑
            </div>
            <div
              class="deleteBtn"
              v-show="btnShow(item)"
              @click="deleteFun(item)"
            >
              删除
            </div>
          </div>
        </div>
      </div>

      <div class="footer">
        <div class="footerLine">
          @2024 杭州起源创新网络科技有限公司
        </div>
        <div class="footerLine">
          浙江省杭州市余杭区科技大道31号2幢4层B8443号
        </div>
        <div class="footerLine">
          联系方式:Support@bunnytown.app 联系手机：18069853772
        </div>
         <div class="footerLine" @click="goUrl()" style="cursor: pointer;">
          浙ICP备18034507号-3
        </div>
      </div>
    </div>

    <loginBody :maskShow="maskShow" @close="maskShow = false"></loginBody>

    <el-dialog :visible.sync="dialogVisible" width="30%" :show-close="false">
      <div class="closeTitle">是否退出登录？</div>
      <div class="closeBtnList">
        <div class="cancel" @click="dialogVisible = false">取消</div>
        <div class="sure" @click="logout()">确认</div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="deleteVisible" width="30%" :show-close="false">
      <div class="closeTitle">是否删除这条活动？</div>
      <div class="closeBtnList">
        <div class="cancel" @click="deleteVisible = false">取消</div>
        <div class="sure" @click="deleteItem()">确认</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import loginBody from "../../components/loginView.vue";

export default {
  name: "contentView",
  computed: {
    ...mapGetters(["user", "loginFlag"]),
  },
  components: {
    loginBody: loginBody,
  },
  data() {
    return {
      isFixed: false,
      maskShow: false,
      phone: "",
      code: "",
      codeStatus: "noSend",
      timer: null,
      times: 60,
      actList: [],
      dialogVisible: false,
      deleteVisible: false,
      deleteData: {},
      sendLoading:false
    };
  },
  methods: {
    deleteItem() {
      this.$request
        .editActivity({
          id: this.deleteData.id,
          is_delete: true,
        })
        .then((res) => {
          if (res.code == 200) {
            this.deleteVisible = false
            this.getAct()
            this.sendLoading = false;
            this.$notify.success({
              title: "成功",
              message: res.message,
            });
          } else {
              this.deleteVisible = false
            this.sendLoading = false;
            this.$notify.error({
              title: "错误",
              message: res.message,
            });
          }
        });
    },
    deleteFun(item) {
      this.deleteData = item;
      this.deleteVisible = true;
    },
    btnShow(item) {
      if (!this.loginFlag) {
        return false;
      } else {
        if (item.user_id == this.user.user_id) {
          return true;
        } else {
          return false;
        }
      }
    },
    verifyCode() {
      //  console.log(this.$store)
      if (!this.code) {
        this.$notify.error({
          title: "错误",
          message: "请输入正确验证码",
        });
        return;
      }

      this.$request
        .verifyCode({
          phone: "+86" + this.phone,
          type: "login",
          code: this.code,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$store.commit("login", res.data);
            this.maskShow = false;
          } else {
            this.$notify.error({
              title: "错误",
              message: res.message,
            });
          }
        });
    },
    sendCode() {
      if (!this.phone) {
        this.$notify.error({
          title: "错误",
          message: "请输入正确手机号",
        });
        return;
      }

      this.$request
        .sendCode({
          phone: "+86" + this.phone,
          type: "login",
        })
        .then((res) => {
          if (res.code == 200) {
            this.times = 60;

            this.codeStatus = "waitIng";

            this.timer = setInterval(() => {
              if (this.times != 0) {
                this.times = this.times - 1;
              } else {
                this.codeStatus = "reSend";
                clearInterval(this.timer);
              }
            }, 1000);
          } else {
            this.$notify.error({
              title: "错误",
              message: res.message,
            });
          }
        });
    },
    // login() {},
    goHome() {
      this.$router.push("contentView");
    },
    goCreateActivity() {
      this.$router.push("createActivity");
    },
    goUrl(){
      window.location.href = 'https://beian.miit.gov.cn'
      // this.$root.window.location.href="https://www.baidu.com"
    },
    goDetail(index) {
      localStorage.setItem("actId", index);
      this.$router.push({ name: "activityDetail" });
    },
    goEdit(item) {
      this.$store.commit("editItem", item);
      this.$router.push("editView");
    },
    scrollFun() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; // 滚动条偏移量
      let offsetTop = document.querySelector(".header").offsetTop; // 要滚动到顶部吸附的元素的偏移量
      this.isFixed = scrollTop > offsetTop ? true : false; // 如果滚动到顶部了，this.isFixed就为true
    },
    logout() {
      this.dialogVisible = false;
      window.localStorage.removeItem("token");
      this.$store.commit("logout");

      this.$notify.success({
        title: "成功",
        message: "退出登录成功",
      });
    },
    getAct(){
this.$request.getActivity().then((res) => {
      console.log(res);
      if (res.code == 200) {
        this.actList = res.data;
      } else {
        this.$notify.error({
          title: "错误",
          message: "意料之外的错误",
        });
      }
    });
    }
  },

  mounted() {
    window.addEventListener("scroll", this.scrollFun);

    if (window.localStorage.getItem("token")) {
      this.$request.getUserInfo().then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$store.commit("login", res.data);
        } else {
          window.localStorage.removeItem("token");
          this.$notify.error({
            title: "错误",
            message: "登录过期，请重新登录",
          });
        }
      });
    }

    this.getAct()
  },
};
</script>

<style lang="scss" scoped>
.contentBody {
  .header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: 0.5s;
    // background-color: aqua;
    .headerContent {
      margin: 0 auto;
      width: 360px;
      // background-color: red;
      height: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .headerLeft {
        font-family: PlayfairDisplayBold;
        font-size: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #fff;

        span {
          font-size: 8px;
          font-family: PlayfairDisplayBold;
          font-weight: 900;
          margin-left: 5px;
        }
      }

      .headerRight {
        .noLogin {
          display: flex;
          align-items: center;
          .block {
            margin-left: 15px;
            color: #fff;
            font-size: 5px;
            cursor: pointer;
            img {
              width: 10px;
              height: 10px;
              border-radius: 999px;
            }
          }
        }
      }
    }
  }

  .imgTop {
    // width: px;
    height: 300px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 300px;
    }
  }

  .body {
    width: 360px;
    margin: 0 auto;
    margin-top: 10px;
    border-top: 1px solid #f1f1f1;

    .actTitle {
      margin-top: 30px;
      .actTop {
        text-align: center;
        font-family: PlayfairDisplayBold;
        font-size: 14px;
      }
      .actBottom {
        font-size: 8px;
        color: #999999;
        text-align: center;
        margin: 10px auto;
        max-width: 70%;
      }
    }

    .actList {
      display: flex;
      flex-wrap: wrap;
      .actItem {
        width: 32%;
        margin-right: 2%;
        // height: 100px;
        margin-top: 30px;

        .actImg {
          width: 100%;
          img {
            width: 100%;
            height: 60px;
            border-radius: 5px;
            cursor: pointer;
          }
        }

        .actLine {
          margin-top: 5px;
          font-size: 5px;
        }

        .actSub {
          height: 10px;
          margin-top: 5px;
          font-size: 4.5px;
          display: flex;
          align-items: center;

          > div {
            cursor: pointer;
          }

          .editBtn {
            margin-left: 10px;
            // color: rgb(71, 71, 255);
            background-color: #000;
            color: #fff;
            padding: 1px 3px;
            border-radius: 1px;
          }

          .deleteBtn {
            margin-left: 5px;
            // color: ;
            background-color: red;
            color: #fff;
            padding: 1px 3px;
            border-radius: 1px;
          }
        }
      }
      .actItem:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  .footer {
    margin-top: 50px;
    text-align: center;
    padding-bottom: 15px;
    .footerLine {
      margin-top: 3px;
      font-size: 4px;
    }
  }
}

.sticky {
  background-color: #000000be;
  .headerLeft {
    color: #fff;
    transition: 0.5s;
  }
}

.animation-in-left {
  animation: mankuaiAnimationLeft 0.5s linear;
}

.animation-in-right {
  animation: mankuaiAnimationRight 0.5s linear;
}

.animation-in-bottom {
  animation: mankuaiAnimationBottom 0.5s linear;
}

@keyframes mankuaiAnimationLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes mankuaiAnimationRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes mankuaiAnimationBottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000000b6;
  padding-bottom: 10vh;

  display: flex;
  align-items: center;
  justify-content: center;

  .loginBody {
    position: relative;
    background-color: #fff;
    padding: 30px 30px;
    width: 200px;

    .close {
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 10px;

      img {
        width: 5px;
      }
    }

    .loginTitle {
      text-align: center;
      font-size: 10px;
      // font-weight: 900;
    }

    .phone {
      width: 100%;
      display: flex;
      align-items: center;
      border-bottom: 0.8px solid #eeeeee;
      padding: 8px 5px;
      font-size: 6px;
      margin-top: 20px;
      .left {
        padding-right: 5px;
        border-right: 0.8px solid #000;
        font-size: 6px !important;
      }

      input {
        font-size: 6px;
        padding-left: 5px;
        flex: 1;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
      }
    }
    .drop {
      // font-size: 5px !important;
    }
    .code {
      width: 100%;
      display: flex;
      align-items: center;
      border-bottom: 0.8px solid #eeeeee;
      padding: 8px 5px;
      font-size: 6px;
      margin-top: 10px;
      .right {
        padding-left: 5px;
        font-weight: 900;
        cursor: pointer;
        // border-right: 0.8px solid #000;
      }

      input {
        font-size: 6px;
        padding-left: 5px;
        flex: 1;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
      }
    }

    .loginBtn {
      cursor: pointer;
      background: #000;
      color: #fff;
      text-align: center;
      padding: 3px;
      border-radius: 999px;
      font-size: 6px;

      margin-top: 20px;
    }

    .orLine {
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .line {
        flex: 1;
        height: 0.5px;
        background-color: #eeeeee;
      }

      .lineText {
        font-size: 6px;
        padding: 10px;
      }
    }

    .otherLogin {
      cursor: pointer;
      // background: #000;
      border: 0.8px solid #000;
      text-align: center;
      padding: 3px;
      border-radius: 999px;
      font-size: 6px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 10px;
        margin-right: 5px;
      }
    }
  }
}
</style>
