import axios from 'axios'
import qs from 'qs';

// 创建axios实例对象
const service = axios.create({
  baseURL: "https://bunnytown-prod.bunnytown.cn",
  // baseURL: "https://bunnytown-test.bunnytown.cn",
  timeout: 10000,
  headers: {
    post: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  },
  emulateJSON: true,
  // withCredentials: true
})
// 请求拦截器
service.interceptors.request.use(
  config => {

    if (window.localStorage.getItem("token")) {
      config.headers.token = window.localStorage.getItem('token');
    }

    if (config.method == 'get') {
      config.paramsSerializer = function (params) {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      }
    }


    return config;

  },
  error => {
    return Promise.reject(error);
  })

// 响应拦截器
service.interceptors.response.use(
  res => {
    return res.data;
  })

export default service
