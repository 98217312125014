
<template>
  <div class="toast" v-if="show">
    {{ message }}
  </div>
</template>
 
<script>
export default {
  name: "toastView",
  data() {
    return {
      show: false,
      message: "",
    };
  },
  methods: {
    showMessage(message) {
      this.message = message;
      this.show = true;
      setTimeout(() => {
        this.show = false;
      }, 1000);
    },
  },
};
</script>
 
<style scoped>
.toast {
  font-size: 20px;
  position: fixed;
  /* bottom: 20px;
  right: 20px; */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(-45deg, #fea93f, #f2680e);
  box-shadow: 0px 0px 20px 0px rgba(4, 0, 0, 0.5);
  border-radius: 10px;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
}
</style>