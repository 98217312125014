<template>
  <div class="loginBody">
    <div class="mask"  v-show="maskShow">
      <div
        class="loginBody"
        v-appear-animation="{ appearClass: 'animation-in-right' }"
      >
        <div class="close" @click="close()">
          <img src="../static/img/close.png" alt="" />
        </div>

        <div class="loginTitle">登录/注册</div>

        <div class="phone">
          <el-dropdown size="mini" class="drop">
            <div class="left">+86</div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>+86</el-dropdown-item>
              <el-dropdown-item>+1</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <!-- <div class="phoneInput"> -->
          <input type="text" placeholder="请输入手机号" v-model="phone" />
          <!-- </div> -->
        </div>

        <div class="code">
          <input
            type="text"
            placeholder="请输入验证码"
            v-model="code"
            maxlength="6"
          />
          <div class="right" @click="sendCode()" v-if="codeStatus == 'noSend'">
            点击发送
          </div>
          <div
            class="right"
            v-if="codeStatus == 'waitIng'"
            style="color: #4e4e4e"
          >
            重新发送（{{ times }}）
          </div>
          <div class="right" @click="sendCode()" v-if="codeStatus == 'reSend'">
            重新发送
          </div>
        </div>

        <div class="loginBtn" @click="verifyCode()">登录</div>

        <!-- <div class="orLine">
          <div class="line"></div>
          <div class="lineText">或</div>
          <div class="line"></div>
        </div> -->

        <!-- <div class="otherLogin">
          <img src="../../static/img/wx.png" alt="" />
          微信登录
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "contentView",
  computed: {
    ...mapGetters(["user", "loginFlag"]),
  },
  props: {
    maskShow: {
      type: Boolean,
      default:false
    }
  },
  data() {
    return {
      phone: "",
      code: "",
      codeStatus: "noSend",
      timer: null,
      times: 60,
    };
  },
  methods: {
    verifyCode() {
      //  console.log(this.$store)
      if (!this.code) {
        this.$notify.error({
          title: "错误",
          message: "请输入正确验证码",
        });
        return;
      }

      this.$request
        .verifyCode({
          phone: "+86" + this.phone,
          type: "login",
          code: this.code,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$store.commit("login", res.data);
            this.close()
          } else {
            this.$notify.error({
              title: "错误",
              message: res.message,
            });
          }
        });
    },
    sendCode() {
      if (!this.phone) {
        this.$notify.error({
          title: "错误",
          message: "请输入正确手机号",
        });
        return;
      }

      this.$request
        .sendCode({
          phone: "+86" + this.phone,
          type: "login",
        })
        .then((res) => {
          if (res.code == 200) {
            this.times = 60;

            this.codeStatus = "waitIng";

            this.timer = setInterval(() => {
              if (this.times != 0) {
                this.times = this.times - 1;
              } else {
                this.codeStatus = "reSend";
                clearInterval(this.timer);
              }
            }, 1000);
          } else {
            this.$notify.error({
              title: "错误",
              message: res.message,
            });
          }
        });
    },
    close(){
        this.$emit("close")
    },
    goUrl(){
      // window.location.href = 'https://beian.miit.gov.cn'
      this.$root.window.location.href="https://www.baidu.com"
    }
  },
};
</script>

<style lang="scss" scoped>
.mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000000b6;
  padding-bottom: 10vh;

  display: flex;
  align-items: center;
  justify-content: center;

  .loginBody {
    position: relative;
    background-color: #fff;
    padding: 30px 30px;
    width: 200px;

    .close {
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 10px;

      img {
        width: 5px;
      }
    }

    .loginTitle {
      text-align: center;
      font-size: 10px;
      // font-weight: 900;
    }

    .phone {
      width: 100%;
      display: flex;
      align-items: center;
      border-bottom: 0.8px solid #eeeeee;
      padding: 8px 5px;
      font-size: 6px;
      margin-top: 20px;
      .left {
        padding-right: 5px;
        border-right: 0.8px solid #000;
        font-size: 6px !important;
      }

      input {
        font-size: 6px;
        padding-left: 5px;
        flex: 1;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
      }
    }
    .drop {
      // font-size: 5px !important;
    }
    .code {
      width: 100%;
      display: flex;
      align-items: center;
      border-bottom: 0.8px solid #eeeeee;
      padding: 8px 5px;
      font-size: 6px;
      margin-top: 10px;
      .right {
        padding-left: 5px;
        font-weight: 900;
        cursor: pointer;
        // border-right: 0.8px solid #000;
      }

      input {
        font-size: 6px;
        padding-left: 5px;
        flex: 1;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
      }
    }

    .loginBtn {
      cursor: pointer;
      background: #000;
      color: #fff;
      text-align: center;
      padding: 3px;
      border-radius: 999px;
      font-size: 6px;

      margin-top: 20px;
    }

    .orLine {
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .line {
        flex: 1;
        height: 0.5px;
        background-color: #eeeeee;
      }

      .lineText {
        font-size: 6px;
        padding: 10px;
      }
    }

    .otherLogin {
      cursor: pointer;
      // background: #000;
      border: 0.8px solid #000;
      text-align: center;
      padding: 3px;
      border-radius: 999px;
      font-size: 6px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 10px;
        margin-right: 5px;
      }
    }
  }
}

.animation-in-right {
  animation: mankuaiAnimationRight 0.5s linear;
}

@keyframes mankuaiAnimationRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

</style>
