<template>
  <div class="contentBody" v-on:scroll="scrollFun">
    <div class="top"></div>
    <div class="header" :class="{ sticky: this.isFixed }">
      <div class="headerContent">
        <div
          class="headerLeft"
          v-appear-animation="{ appearClass: 'animation-in-left' }"
          @click="goHome()"
        >
          Bunnytown<span>起源创新</span>
        </div>
        <div
          class="headerRight"
          v-appear-animation="{ appearClass: 'animation-in-right' }"
        >
          <div class="noLogin">
            <div class="block" @click="goHome()">首页</div>
            <div class="block" @click="goCreateActivity()">信息发布</div>
            <div class="block" v-if="!loginFlag" @click="maskShow = true">
              注册/登录
            </div>
            <div class="block" v-else @click="dialogVisible = true">
              <img :src="user.avatar_url" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="body" v-loading="sendLoading">
      <div
        class="activityTitle"
        v-appear-animation="{ appearClass: 'animation-in-right' }"
      >
        <div class="left">
          <input
            type="text"
            placeholder="请输入活动标题"
            maxlength="50"
            v-model="title"
          />
        </div>
        <div class="right">{{ title.length }}/50</div>
      </div>

      <div class="uploader" v-show="false">
        <input
          type="file"
          class="fonts"
          name=""
          @change="convertToBase64"
          ref="updata"
          accept="image/*"
          id="upload"
        />
      </div>

      <label for="upload">
        <div
          class="activityImg"
          v-appear-animation="{ appearClass: 'animation-in-left' }"
          v-loading="loading"
        >
          <div class="mask">
            <div class="addBtn" v-show="!picUrl">
              <img src="../../static/img/ADD.png" alt="" />
            </div>
            <div class="addText" v-show="!picUrl">上传封面</div>
            <div class="showImg" v-show="picUrl">
              <img :src="picUrl" alt="" />
            </div>
          </div>
        </div>
      </label>

      <div class="upBor"></div>

      <div class="lineInput">
        <div class="left">联系信息</div>
        <div class="right">
          <input type="text" placeholder="请输入联系信息" v-model="contact" />
        </div>
      </div>

      <div class="lineInput">
        <div class="left">活动地址</div>
        <div class="right">
          <input type="text" placeholder="请输入活动地址" v-model="address" />
        </div>
      </div>

      <div
        class="contentTitle"
        v-appear-animation="{ appearClass: 'animation-in-right' }"
      >
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          placeholder="输入活动内容，在这里介绍你的活动，可对文字进行自由排版"
          v-model="content"
        ></textarea>
      </div>

      <div
        class="subBtn"
        v-appear-animation="{ appearClass: 'animation-in-left' }"
        @click="createActivity()"
      >
        修改
      </div>

      <div class="footer">
        <div class="footerLine">
          @2024 杭州起源创新网络科技有限公司
        </div>
        <div class="footerLine">
          浙江省杭州市余杭区科技大道31号2幢4层B8443号
        </div>
        <div class="footerLine">
          联系方式:Support@bunnytown.app 联系手机：18069853772
        </div>
        <div class="footerLine" @click="goUrl()" style="cursor: pointer;">
          浙ICP备18034507号-3
        </div>
      </div>
    </div>

    <loginBody :maskShow="maskShow" @close="maskShow = false"></loginBody>

    <el-dialog :visible.sync="dialogVisible" width="30%" :show-close="false">
      <div class="closeTitle">是否退出登录？</div>
      <div class="closeBtnList">
        <div class="cancel" @click="dialogVisible = false">取消</div>
        <div class="sure" @click="logout()">确认</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import loginBody from "../../components/loginView.vue";
export default {
  computed: {
    ...mapGetters(["user", "loginFlag", "editData"]),
  },
  components: {
    loginBody: loginBody,
  },
  name: "contentView",
  data() {
    return {
      isFixed: false,
      title: "",
      imageBase64: null,
      picUrl: "",
      content: "",
      loading: false,
      sendLoading: false,
      maskShow: false,
      contact: "",
      address: "",
      dialogVisible: false,
      actId: "",
    };
  },
  methods: {
    createActivity() {
      if (!this.loginFlag) {
        this.maskShow = true;
        return;
      }

      if (!this.title) {
        this.$notify.error({
          title: "错误",
          message: "请输入活动标题",
        });
        return;
      }

      if (!this.picUrl) {
        this.$notify.error({
          title: "错误",
          message: "请上传活动图片",
        });
        return;
      }

      if (!this.content) {
        this.$notify.error({
          title: "错误",
          message: "请输入活动内容",
        });
        return;
      }

      if (!this.contact) {
        this.$notify.error({
          title: "错误",
          message: "请输入联系方式",
        });
        return;
      }

      if (!this.address) {
        this.$notify.error({
          title: "错误",
          message: "请输入活动地址",
        });
        return;
      }

      this.sendLoading = true;
      this.$request
        .editActivity({
          id: this.actId,
          title: this.title,
          pic: {
            url: this.picUrl,
          },
          content: this.content,
          contact: this.contact,
          address: this.address,
          is_delete: false,
        })
        .then((res) => {
          if (res.code == 200) {
            console.log(res);
            this.sendLoading = false;
            this.$notify.success({
              title: "成功",
              message: res.message,
            });
            this.$router.push("contentView");
          } else {
            this.sendLoading = false;
            this.$notify.error({
              title: "错误",
              message: res.message,
            });
          }
        });
    },
     goUrl(){
      window.location.href = 'https://beian.miit.gov.cn'
      // this.$root.window.location.href="https://www.baidu.com"
    },
    goHome() {
      this.$router.push("contentView");
    },
    convertToBase64(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.loading = true;
          this.imageBase64 = e.target.result.replace(
            "data:image/png;base64,",
            ""
          );

          this.imageBase64 = this.imageBase64.replace(
            "data:image/jpeg;base64,",
            ""
          );

          this.imageBase64 = this.imageBase64.replace(
            "data:image/jpg;base64,",
            ""
          );

          axios
            .post(
              "https://1258414891-gdi3ob1t0w-sh.scf.tencentcs.com/bunnytown/api/profile/pic/upload",

              {
                avatar: this.imageBase64,
              },
              {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                  token: window.localStorage.getItem("token"),
                },
              }
            )

            .then((response) => {
              if (response.data.code == 200) {
                this.picUrl = response.data.data.avatar[0].url;
                this.loading = false;
              } else {
                this.$notify.error({
                  title: "错误",
                  message: response.data.data.message,
                });
                this.loading = false;
              }
            })

            .catch((error) => {
              // 处理错误
              console.log(error);

              this.$notify.error({
                title: "错误",
                message: "预料之外的而错误",
              });
              this.loading = false;
            });
        };
        reader.readAsDataURL(file);
      }
    },
    scrollFun() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; // 滚动条偏移量
      let offsetTop = document.querySelector(".header").offsetTop; // 要滚动到顶部吸附的元素的偏移量
      this.isFixed = scrollTop > offsetTop ? true : false; // 如果滚动到顶部了，this.isFixed就为true
    },
    goCreateActivity() {
      this.$router.push("createActivity");
    },
    logout() {
      this.dialogVisible = false;
      window.localStorage.removeItem("token");
      this.$store.commit("logout");

      this.$notify.success({
        title: "成功",
        message: "退出登录成功",
      });
    },
  },
  mounted() {
    window.scrollTo({
      top: 0,
    });

    this.title = this.editData.title;
    this.content = this.editData.content;
    this.contact = this.editData.contact;
    this.address = this.editData.address;
    this.actId = this.editData.id;
    this.picUrl = this.editData.pic;
    window.addEventListener("scroll", this.scrollFun);
    // if (window.localStorage.getItem("token")) {
    //   this.$request.getUserInfo().then((res) => {
    //     console.log(res);
    //     if (res.code == 200) {
    //       this.$store.commit("login", res.data);
    //     } else {
    //       window.localStorage.removeItem("token");
    //       this.$notify.error({
    //         title: "错误",
    //         message: "登录过期，请重新登录",
    //       });
    //     }
    //   });
    // }
  },
};
</script>

<style lang="scss" scoped>
.contentBody {
  .header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: 0.5s;
    border-bottom: 0.8px solid #eeeeee;
    .headerContent {
      margin: 0 auto;
      width: 360px;
      // background-color: red;
      height: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .headerLeft {
        font-family: PlayfairDisplayBold;
        font-size: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;

        span {
          font-size: 8px;
          font-family: PlayfairDisplayBold;
          font-weight: 900;
          margin-left: 5px;
        }
      }

      .headerRight {
        .noLogin {
          display: flex;
          align-items: center;
          .block {
            margin-left: 15px;
            // color: #fff;
            font-size: 5px;
            cursor: pointer;
            img {
              width: 10px;
              height: 10px;
              border-radius: 999px;
            }
          }
        }
      }
    }
  }

  .body {
    width: 250px;
    margin: 0 auto;
    margin-top: 10px;
    padding-top: 30px;
    // min-height: 100vh;
    // background-color: #000;

    .activityTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 0.8px solid #eeeeee;
      padding: 5px 0px;

      .left {
        font-weight: 900;
        color: #999999;
        font-size: 10px;
        flex: 1;
        input {
          width: 90%;
          font-size: 10px;
          padding-left: 5px;
          flex: 1;
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
        }
      }

      .right {
        color: #999999;
        font-size: 6px;
      }
    }

    .upBor {
      border-top: 0.8px solid #eeeeee;
    }

    .activityImg {
      width: 150px;
      min-height: 100px;
      margin: 8px 0px;
      background-color: #f9f9f9;
      border: 0.8px dashed #d4d4d4;

      border-radius: 3px;
      cursor: pointer;

      .mask {
        width: 100%;
        min-height: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .addBtn {
          background-color: #d4d4d4;
          border-radius: 999px;
          width: 20px;

          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 10px;
            height: 10px;
          }
        }

        .addText {
          margin-top: 5px;
          font-size: 6px;
        }
      }

      .showImg {
        width: 100%;
        // height: 100%;
        img {
          width: 100%;
          // height: 100%;
        }
      }
    }

    .contentTitle {
      padding: 5px 0px;
      border-top: 0.8px solid #eeeeee;
      width: 100%;
      textarea {
        width: 100%;
        font-size: 6px;
      }
    }

    .subBtn {
      display: inline;
      background: #000;
      color: #fff;
      font-size: 7px;
      padding: 4px 25px;
      border-radius: 999px;
      cursor: pointer;
    }
  }

  .footer {
    margin-top: 50px;
    text-align: center;
    padding-bottom: 15px;
    .footerLine {
      margin-top: 3px;
      font-size: 4px;
    }
  }
}

.lineInput {
  display: flex;
  align-items: center;
  margin: 20px 0px;

  .left {
    font-size: 6px;
    font-weight: 900;
  }
  .right {
    color: #999999;
    font-size: 6px;
    flex: 1;
    input {
      width: 90%;
      font-size: 6px;
      padding-left: 5px;
      flex: 1;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }
}

.sticky {
  background-color: #000000be;
  .headerLeft {
    color: #fff;
    transition: 0.5s;
  }
  .headerRight {
    color: #fff;
    transition: 0.5s;
  }
}

.animation-in-left {
  animation: mankuaiAnimationLeft 0.5s linear;
}

.animation-in-right {
  animation: mankuaiAnimationRight 0.5s linear;
}

.animation-in-bottom {
  animation: mankuaiAnimationBottom 0.5s linear;
}

@keyframes mankuaiAnimationLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes mankuaiAnimationRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes mankuaiAnimationBottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

textarea {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
</style>
