import axios from '@/utils/axios'





export function sendCode(data) {
  return axios({
    url: '/bunnytown/api/authentic/sms/send',
    method: 'POST',
    data
  })
}


export function verifyCode(data) {
  return axios({
    url: '/bunnytown/api/authentic/sms/verify',
    method: 'POST',
    data
  })
}


export function getUserInfo(data) {
  return axios({
    url: '/bunnytown/api/profile',
    method: 'GET',
    data
  })
}

export function postAct(data) {
  return axios({
    url: '/bunnytown/api/chat/activity',
    method: 'POST',
    data
  })
}

export function upload(data) {
  return axios({
    url: '/bunnytown/api/profile/pic/upload',
    method: 'POST',
    data
  })
}


export function getActivity(data) {
  return axios({
    url: '/bunnytown/api/chat/activity',
    method: 'GET',
    data
  })
}

export function editActivity(data) {
  return axios({
    url: '/bunnytown/api/chat/activity',
    method: 'PUT',
    data
  })
}

export default {
  sendCode,
  verifyCode,
  getUserInfo,
  postAct,
  upload,
  getActivity,
  editActivity
}
