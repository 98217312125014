import Vue from 'vue'
import App from './App.vue'

// import 'lib-flexible'
import 'amfe-flexible'

import router from "./router"
import request from './api/apiList';

// 引入状态管理
import store from './vuex/store';



import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.prototype.$request = request

Vue.directive('appear-animation', {
  bind(el, binding) {
    const options = binding.value || {};
 
    let hasEntered = false; // 用于追踪元素是否已经进入视图
 
    // sjhaihbd-in
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !hasEntered) {
            // 元素进入视图，添加CSS类触发动画
            el.classList.add(options.appearClass || 'slide-in');
            hasEntered = true; // 更新状态
          
        } else if (!entry.isIntersecting && hasEntered) {
          // 元素离开视图，移除CSS类
          // el.classList.remove(options.appearClass || 'slide-in');
          // hasEntered = false; // 更新状态
        }
      });
    }, options.intersectionOptions || {});
 
    observer.observe(el);
  },
});




// 提示组件
import toastView from './components/toastView.vue';

const ToastPlugin = {
  install(Vue) {
    Vue.prototype.$toast = new Vue(toastView).$mount();
    document.body.appendChild(Vue.prototype.$toast.$el);
  },
};

Vue.use(ToastPlugin);


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')