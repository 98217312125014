<template>
  <div class="contentBody" v-on:scroll="scrollFun">
    <div class="header" :class="{ sticky: this.isFixed }">
      <div class="headerContent">
        <div
          class="headerLeft"
          v-appear-animation="{ appearClass: 'animation-in-left' }"
          @click="goHome()"
        >
           Bunnytown<span>起源创新</span>
        </div>
        <div
          class="headerRight"
          v-appear-animation="{ appearClass: 'animation-in-right' }"
        >
          <div class="noLogin">
            <div class="block" @click="goHome()">首页</div>
            <div class="block" @click="goCreateActivity()">信息发布</div>
            <div class="block" v-if="!loginFlag" @click="maskShow = true">
              注册/登录
            </div>
            <div class="block" v-else @click="dialogVisible = true">
              <img :src="user.avatar_url" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="body">
      <div class="bodyLeft">
        <div class="detailImg">
          <img :src="actDetail.pic" alt="" />
        </div>
      </div>
      <div class="bodyRight">
        <div class="detailTitle">{{ actDetail.title }}</div>

        <div class="lineInput">
          <div class="left">联系信息</div>
          <div class="right">
            {{ getSeeText(actDetail.contact) }}
          </div>
        </div>

        <div class="lineInput">
          <div class="left">活动地址</div>

          <div class="right" v-if="loginFlag">
            {{ actDetail.address }}
          </div>
          <div class="right" v-else>
            <span style="cursor: pointer" @click="maskShow = true"
              >登录后查看</span
            >
          </div>
        </div>

        <div class="seeDetail" v-if="!loginFlag" @click="maskShow = true">
          <div class="btn">查看完整联系方式</div>
        </div>

        <div class="detailContent">
          {{ actDetail.content }}
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="footerLine">
        @2024 杭州起源创新网络科技有限公司
      </div>
      <div class="footerLine">浙江省杭州市余杭区科技大道31号2幢4层B8443号</div>
      <div class="footerLine">
        联系方式:Support@bunnytown.app 联系手机：18069853772
      </div>
       <div class="footerLine" @click="goUrl()" style="cursor: pointer;">
          浙ICP备18034507号-3
        </div>
    </div>

    <loginBody :maskShow="maskShow" @close="maskShow = false"></loginBody>

    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      :show-close='false'
    >
     <div class="closeTitle">是否退出登录？</div>
      <div class="closeBtnList">
        <div class="cancel" @click="dialogVisible = false">取消</div>
        <div class="sure" @click="logout()">确认</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import loginBody from "../../components/loginView.vue";
export default {
  computed: {
    ...mapGetters(["user", "loginFlag"]),
  },
  components: {
    loginBody: loginBody,
  },
  name: "contentView",
  data() {
    return {
      isFixed: false,
      actDetail: {
        pic: "",
        title: "",
        content: "",
        contact: "",
        address: "",
       
      },
      maskShow: false,
       dialogVisible:false
    };
  },
  methods: {
    getSeeText(str) {
      if (this.loginFlag) {
        return str;
      } else {
        return str.replace(/.{4}$/, "****");
      }
    },
    goHome() {
      this.$router.push("contentView");
    },
    goCreateActivity() {
      if (!this.loginFlag) {
        this.maskShow = true;
        return;
      }
      this.$router.push("createActivity");
    },
    goUrl(){
      window.location.href = 'https://beian.miit.gov.cn'
      // this.$root.window.location.href="https://www.baidu.com"
    },
    scrollFun() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; // 滚动条偏移量
      let offsetTop = document.querySelector(".header").offsetTop; // 要滚动到顶部吸附的元素的偏移量
      console.log(scrollTop, offsetTop);
      this.isFixed = scrollTop > offsetTop ? true : false; // 如果滚动到顶部了，this.isFixed就为true
    },
    logout() {
      this.dialogVisible = false
       window.localStorage.removeItem("token");
        this.$store.commit("logout");

        this.$notify.success({
            title: "成功",
            message: "退出登录成功",
          });
    },
  },
  mounted() {
    window.scrollTo({
      top: 0,
    });
    window.addEventListener("scroll", this.scrollFun);

    if (window.localStorage.getItem("token")) {
      this.$request.getUserInfo().then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$store.commit("login", res.data);
        } else {
          window.localStorage.removeItem("token");
          this.$notify.error({
            title: "错误",
            message: "登录过期，请重新登录",
          });
        }
      });
    }

    let key = localStorage.getItem("actId");

    this.$request.getActivity().then((res) => {
      console.log(res);
      if (res.code == 200) {
        this.actDetail = res.data[key];
      } else {
        this.$notify.error({
          title: "错误",
          message: "意料之外的错误",
        });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.contentBody {
  .header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: 0.5s;
    border-bottom: 0.8px solid #eeeeee;
    .headerContent {
      margin: 0 auto;
      width: 360px;
      // background-color: red;
      height: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .headerLeft {
        font-family: PlayfairDisplayBold;
        font-size: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;

        span {
          font-size: 8px;
          font-family: PlayfairDisplayBold;
          font-weight: 900;
          margin-left: 5px;
        }
      }

      .headerRight {
        .noLogin {
          display: flex;
          align-items: center;
          .block {
            margin-left: 15px;
            // color: #fff;
            font-size: 5px;
            cursor: pointer;
            img {
              width: 10px;
              height: 10px;
              border-radius: 999px;
            }
          }
        }
      }
    }
  }

  .body {
    width: 360px;
    margin: 0 auto;
    margin-top: 10px;
    padding-top: 30px;
    display: flex;
    // align-items: center;

    .bodyLeft {
      width: 50%;
      padding: 0px 20px;
      border-right: 0.5px solid #e5e5e5;
    }
    .bodyRight {
      width: 50%;
      padding: 0px 20px;
    }

    .detailTitle {
      font-size: 8px;
      font-weight: 900;
    }

    .detailImg {
      img {
        width: 100%;
      }
    }

    .detailContent {
      font-size: 5px;
      white-space: pre-wrap;
    }
  }

  .footer {
    margin-top: 50px;
    text-align: center;
    padding-bottom: 15px;
    .footerLine {
      margin-top: 3px;
      font-size: 4px;
    }
  }
}

.seeDetail {
  display: flex;
  margin: 10px 0px;
  .btn {
    cursor: pointer;
    background-color: #000;
    color: #fff;
    font-size: 4px;
    // display: inline;
    padding: 3px 8px;
    box-sizing: border-box;
  }
}

.lineInput {
  display: flex;
  align-items: center;
  margin: 10px 0px;

  .left {
    font-size: 6px;
    font-weight: 900;
  }
  .right {
    font-size: 6px;
    padding-left: 5px;
    flex: 1;
  }
}

.sticky {
  background-color: #000000be;
  .headerLeft {
    color: #fff;
    transition: 0.5s;
  }
  .headerRight {
    color: #fff;
    transition: 0.5s;
  }
}

.animation-in-left {
  animation: mankuaiAnimationLeft 0.5s linear;
}

.animation-in-right {
  animation: mankuaiAnimationRight 0.5s linear;
}

.animation-in-bottom {
  animation: mankuaiAnimationBottom 0.5s linear;
}

@keyframes mankuaiAnimationLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes mankuaiAnimationRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes mankuaiAnimationBottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
