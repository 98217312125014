import Vue from "vue"
import vueRouter from "vue-router"

Vue.use(vueRouter)


const originalPush = vueRouter.prototype.push
//修改原型对象中的push方法
vueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
//解决vue路由重复导航错误


// 初始页面
import indexView from "../views/indexView"


// 一级页面 ----------------
// // 登录页面
// import loginView from "../views/index/loginView"
// 视图主页面
import contentView from "../views/content/contentView"
import createActivity from "../views/content/createActivity"
import activityDetail from "../views/content/activityDetail"
import editView from "../views/content/editView"


const routes = [
    {
        path: "",
        redirect: "/indexView/contentView"
    },

    {
        path: "/indexView",
        component: indexView,
        children: [
            // {
            //     path: 'loginView',
            //     component: loginView
            // },
            {
                path: 'contentView',
                component: contentView,
            },
            {
                path: 'createActivity',
                component: createActivity,
            },
            {
                path: 'editView',
                component: editView,
            },
            {
                path: 'activityDetail',
                name: 'activityDetail',
                component: activityDetail,
            }
        ]
    }
]

const router = new vueRouter({
    routes
})

export default router